<template>
  <v-container v-if="userRole.toUpperCase().includes('BLACKLIST')">
    <div>
      <v-row
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('../../assets/bus-homepage.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0 mt-4" cols="12">
          <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container v-else-if="loadComponent">
    <v-row v-if="(itemFF.length === 0 || !isOnline)" justify="center" class="null-item">
      <v-col class="d-flex justify-center pb-0" cols="10">
        <v-img
          class="image"
          :src="require('../../assets/icon/no-trip-history.svg')"
        ></v-img>
      </v-col>
      <v-col class="d-flex justify-center py-0" cols="12">
        <h2 class="notfication">{{$t('NoTrip')}}</h2>
      </v-col>
      <v-col class="d-flex justify-center mt-3">
        <v-btn
          v-if="$partnerACL.isShowButton('purchase')"
          :color="$partnerACL.getButtonColor()"
          rounded
          width="80%"
          class="capitalize body-1"
          @click="clickPurchase()"
        >
          {{$t('PurchaseTicket')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(item) in itemFF" :key="item.ticket_token">
      <!-- <CardRecentTrip
        :RouteName="item.route_name"
        :OriginalAcronym="item.orig_loc"
        :OriginalName="item.original_loc"
        :DestinationAcronym="item.dest_loc"
        :DestinationName="item.destination_loc"
        :DateTrip="item.trip_in_timestamp"
        :Time="item.Hour"
        :TicketUUID="item.trip_uuid"
        :Description="item.route_loc"
        :TypeTrip="item.trip_ticket_type"
        :MaxTrip="item.max_trip"
        :MaxTripPerDay="item.max_trip_perday"
        :UsedTicket="item.used_ticket"
        :FareName="item.fare_name"
        :FareRule="item.fare_rule"
        :ExpiredNote="item.expired_note"
        :RouteCode="item.route_code"
        :DepartureTime="item.trip_out_timestamp"
        :BusName="item.bus_name"
        :DriverName="item.bus_staff"
        :SerialNumber="item.serial_number"
      /> -->
      <CardRecentTrip
        :RouteName="item.fare_name"
        :OriginalName="item.original_loc"
        :OriginalAcronym="item.orig_loc"
        :DestinationAcronym="item.dest_loc"
        :DestinationName="item.destination_loc"
        :DateTrip="item.used_date"
        :TicketUUID="item.ticket_id"
        :TypeTrip="item.fare_type"
        :MaxTrip="item.max_trip"
        :MaxTripPerDay="item.max_trip_perday"
        :UsedTicket="item.used_ticket"
        :FareName="item.fare_name"
        :FareRule="item.fare_rule"
        :ExpiredNote="getExpiredNote(item.fare_rule)"
        :Price="convertPriceByCurrency(Number(item.price), item.currency_code)"
        :CurrencyCode="localCurrency"
        :FleetTypeDetail="item.fleet_type_detail"
      />
    </v-row>
    <v-row class="d-flex justify-center mt-3" v-if="eof && hasScroll">
      <b>{{ $t("Eof") }}</b>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import CardRecentTrip from "@/components/CardRecentTrip.vue";
export default {
  name: 'MyTrip',
  components: { CardRecentTrip },
  data() {
    return {
      notifSuccess: false,
      notifFailed: false,
      itemFF: [],
      psg_id: store.state.auth.userData.psg_id,
      bottom: false,
      page: 0,
      limit: 10,
      lastPage: 2,
      eof: false,
      loadComponent: false,
      hasScroll: false,
      isOnline: navigator.onLine,
      localCurrency: localStorage.getItem('currency') || 'USD'
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom & !this.eof) {
        this.addHistory()
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    window.removeEventListener("touchmove", () => {
      this.bottom = this.bottomVisible();
    });
  },
  computed: {
    userRole() {
      return this.$store.state.auth.user_role;
    },
  },
  async created() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });

    window.addEventListener("touchmove", () => {
      this.bottom = this.bottomVisible();
    });

    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
  },
  methods: {
    clickPurchase() {
      const purchaseButton = this.$partnerACL.getMetaButton('purchase');
      if (purchaseButton) {
        if (purchaseButton.menu_type == "wsc_menu") {
          this.$router.push({
            name: purchaseButton.redirect_name,
          });
        } else {
          window.location = purchaseButton.redirect_path;
        }
      } else {
        this.$router.push({
          name: "Ticket",
        });
      }
    },
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    addHistory() {
      this.page = this.page + 1;
      const param = {
        payload: {
          is_recent: false
        },
        page: this.page,
        limit: this.limit
      }
      if (this.page <= this.lastPage) {
        this.$store
          .dispatch("mytrip/triphistory", param)
          .then((response) => {
            this.loadComponent = true
            for (let data of response.data) {
              // data.route_code = data.route_code.trim()
              this.itemFF.push(data)
            }
            this.lastPage = response.lastPage
            if (response.page >= response.lastPage) {
              this.eof = true;
            }
          })
          .catch((err) => {
            this.loadComponent = true
            console.log(err);
          });
      }

    },
    convertPriceByCurrency(price, currency){
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    getExpiredNote(fareRule) {
      return fareRule.expired_on_same_day ? 'ExpiredOnSameDay' :
        fareRule.valid_days != null && !fareRule.expired_on_same_day ? 'ExpiredAfterPurchase' :
          fareRule.expired_after_usage ? 'ExpiredAfterUsage' :
            fareRule.validate_close_date == null ? 'Unlimited' :
              'ValidationPeriod'
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      // this.addHistory()
      this.hasScroll = true
    })
    this.addHistory()
  }
}
</script>
<style lang="scss" scoped>
.myticket {
  background-color: rgba(245, 255, 247, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notfication {
  font-size: 20px;
  font-weight: 500;
}

.null-item {
  position: absolute;
  top: 46%;
  right: 50%;
  transform: translate(46%, -50%);
}

.capitalize {
  text-transform: capitalize;
}
</style>