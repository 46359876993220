<template>
  <v-col class="pb-0">
    <v-card 
      outlined
      class="shadow-card" :class="type">
      <v-container class="pt-0 pb-0">
        <v-row align="center">
          <v-col
            cols="8"
            style="border-right: 1px dashed #c4c4c4"
          >
            <v-container class="pa-0">
              <v-row>
                <v-col class="text-center pb-0" cols="12">
                  <!-- <span class="route-code caption">{{RouteCode}}</span> -->
                  <b class="pl-1">{{RouteName}}</b>
                  <!-- <img
                    :src="require('./../assets/icon/info.svg')"
                    alt="icon"
                    class="around pl-1"
                  /> -->
                </v-col>
                <v-col cols="12" class="pa-0 mt-2 pb-3">
                  <v-row justify="center" class="px-3" fill-height>
                    <v-col cols="5" class="text-center pa-0">
                      <div class="bth d-flex justify-center">{{ OriginalAcronym }}</div>
                      <div class="place-desc d-flex justify-center loc-wrap">{{ OriginalName }}</div>
                    </v-col>
                    <v-col cols="auto" class="text-center pa-0 pt-2 pt-sm-5">
                      <img :src="require('@/assets/home/bus.svg')" /><br />
                    </v-col>
                    <v-col cols="5" class="text-center loc-text pa-0">
                      <div class="bth d-flex justify-center">{{ DestinationAcronym }}</div>
                      <div class="place-desc d-flex justify-center loc-wrap">{{ DestinationName }}</div>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col class="my-0 py-0 text-center">
                      <div class="caption text-grey font-weight-regular mt-1"><i> {{$t("TicketUsedDate")}}: {{Date_}}</i></div>
                    </v-col>
                  </v-row> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="4" class="pt-0 pb-0" align="center">
            <!-- <v-row>
              <v-col class="text-center px-0 py-0">
                <span style="font-size: 0.60rem">{{SerialNumber}}</span>
              </v-col>
            </v-row> -->
            <v-row align="center">
              <v-col class="py-0">
                <v-btn v-if="$partnerACL.isShowButton('detail')" @click="clickDetail(TypeTrip)" small rounded block outlined :color="$partnerACL.getButtonColor()">
                  <span class="capitalize">{{$t('Details')}}</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center px-0 py-0">
                <div class="caption text-grey font-weight-regular mt-1"><i> {{Date_}} </i></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";
export default {
  name: "CardRecentTrip",
  props: {
    RouteName: {
      type: String,
      required: true,
    },
    OriginalAcronym: {
      type: String,
      required: true,
    },
    OriginalName: {
      type: String,
      required: true,
    },
    DestinationAcronym: {
      type: String,
      required: true,
    },
    DestinationName: {
      type: String,
      required: true,
    },
    DateTrip: {
      type: String,
      required: true,
    },
    Description: {
      type: Array,
      required: false,
    },
    TicketUUID: {
      type: String,
      required: true,
    },
    TypeTrip: {
      type: String,
      required: true,
    },
    MaxTrip: {
      type: String,
      required: true,
    },
    MaxTripPerDay: {
      type: String,
      required: true,
    },
    UsedTicket: {
      type: Number,
      required: true,
    },
    FareName: {
      type: String,
      required: true,
    },
    FareRule: {
      type: Object,
      required: true,
    },
    ExpiredNote: {
      type: String,
      required: false,
    },
    RouteCode: {
      type: String,
      required: false,
    },
    DepartureTime: {
      type: String,
      required: false,
    },
    BusName: {
      type: String,
      required: false,
    },
    DriverName: {
      type: Array,
      required: false,
    },
    SerialNumber: {
      type: String,
      required: false,
    },
    Price: {
      type: Number,
      required: false,
    },
    CurrencyCode: {
      type: String,
      required: false,
    },
    FleetTypeDetail: {
      type: Object,
      required: false,
    }
  },
  data: function () {
    return {
      // Uuid_: this.TicketUUID.replace(/-/g, '').substring(0, 16).toUpperCase(),
      Uuid_: this.TicketUUID,
      Date_: "",
      type: ''
    }
  },
  created() {
    moment.locale(this.$i18n.locale)
  },
  mounted() {
    this.Date_ = moment.utc(this.DateTrip).format('DD-MM-YYYY HH:mm')
    if (this.TypeTrip == "1") {
      this.type = "single-trip"
    } else if (this.TypeTrip == "3") {
      this.type = "multidays"
    } else {
      this.type = "student"
    }
  },
  methods: {
    format_uuid(uuid) {
      var array = [];
      var len = 16;
      var i;
      for (i = 0, len; i < len; i += 4) {
        array.push(uuid.substr(i, 4))
      }
      return array.join(' ')
    },
    clickDetail() {
      let detail = null

      detail = {
        routename: this.RouteName,
        original: this.OriginalAcronym,
        originalName: this.OriginalName,
        destination: this.DestinationAcronym,
        destinationName: this.DestinationName,
        date: moment.utc(this.DateTrip).format('DD MMMM YYYY HH:mm'),
        ticket: this.SerialNumber,
        description: this.Description,
        max_trip: this.MaxTrip,
        max_trip_perday: this.MaxTripPerDay,
        used_ticket: this.UsedTicket,
        fare_name: this.FareName,
        fare_rule: this.FareRule,
        expired_note: this.ExpiredNote,
        departureTime: moment.utc(this.DepartureTime).format('HH:mm DD-MM-YYYY'),
        busName: this.BusName,
        driverName: this.DriverName,
        routeCode: this.RouteCode,
        ticket_type: this.TypeTrip,
        price: this.Price,
        currency_code: this.CurrencyCode,
        fleet_type_detail: this.FleetTypeDetail
      }

      const detailButton = this.$partnerACL.getMetaButton('detail');
      if (detailButton) {
        if (detailButton.menu_type == "wsc_menu") {
          this.$router.push({
            name: detailButton.redirect_name,
            params: {
              detail: detail
            }
          })
        } else {
          window.location = detailButton.redirect_path;
        }
      } else {
        this.$router.push({
          name: "MyTripInDetail",
          params: {
            detail: detail
          }
        })
      }

      // var detail;
      // if(this.TypeTrip == "1" || this.TypeTrip == "5"){
      //   detail = {
      //     routename : this.RouteName,
      //     original : this.OriginalAcronym,
      //     originalName : this.OriginalName,
      //     destionation : this.DestinationAcronym,
      //     destinationName : this.DestinationName,
      //     date: moment.utc(this.DateTrip).format('DD MMMM YYYY HH:mm'),
      //     ticket: this.SerialNumber,
      //     description : this.Description,
      //     departureTime: moment.utc(this.DepartureTime).format('HH:mm DD/MM/YYYY'),
      //     busName : this.BusName,
      //     driverName:this.DriverName,
      //     routeCode: this.RouteCode,
      //     ticket_type:this.TypeTrip
      //   }
      // } else {
      //   detail = {
      //     routename : this.RouteName,
      //     original : this.OriginalAcronym,
      //     originalName : this.OriginalName,
      //     destionation : this.DestinationAcronym,
      //     destinationName : this.DestinationName,
      //     date: moment.utc(this.DateTrip).format('DD MMMM YYYY HH:mm'),
      //     ticket: this.SerialNumber,
      //     description : this.Description,
      //     max_trip: this.MaxTrip,
      //     max_trip_perday : this.MaxTripPerDay,
      //     used_ticket: this.UsedTicket,
      //     fare_name: this.FareName,
      //     fare_rule: this.FareRule,
      //     expired_note: this.ExpiredNote,
      //     departureTime: moment.utc(this.DepartureTime).format('HH:mm DD/MM/YYYY'),
      //     busName : this.BusName,
      //     driverName:this.DriverName,
      //     routeCode: this.RouteCode,
      //     ticket_type:this.TypeTrip
      //   }
      // }

      // const detailButton = this.$partnerACL.getMetaButton('detail');
      // if(detailButton){
      //   if(detailButton.menu_type == "wsc_menu"){
      //     this.$router.push({ 
      //       name: detailButton.redirect_name,
      //       params : { 
      //         detail : detail
      //       }
      //     })
      //   }else {
      //     window.location = detailButton.redirect_path;
      //   }
      // }else{
      //   this.$router.push({ 
      //     name: "MyTripInDetail",
      //     params : { 
      //       detail : detail
      //     }
      //   })
      // } 

      //---

      // else {
      //   if(this.TypeTrip == "01" || this.TypeTrip == "05"){
      //     this.$router.push({ 
      //       name: "TripDetail",
      //       params : { detail : detail }
      //     });
      //   } else if(this.TypeTrip == "03"){
      //     this.$router.push({ 
      //       name: "TripDetailMultidays",
      //       params : { detail : detail }
      //     });
      //   }
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
// .transparent {
//   background-color: transparent !important;
//   border: 1px solid #4bb14e !important;
// }
.shadow {
  box-shadow: 0px 3px 1px -10px rgb(0 0 0 / 50%),
    0px 2px 2px 0px rgb(0 0 0 / 5%), 0px 1px 5px 0px rgb(0 0 0 / 2%) !important;
}

.capitalize {
  text-transform: capitalize;
}

.btn-overflow {
  max-width: inherit;
  position: relative;
  overflow: overlay;
}

.shadow-card- {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.single-trip {
  border: 2.5px solid #732269;
}

.multidays {
  border: 2.5px solid #F29900;
}

.student {
  border: 2.5px solid #53C1FF;
}

.detail-text {
  font-size: 7.5px;
}

.route-code {
  border-radius: 50%;
  padding: 2px 2px;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  background-color: #6397d7;
}

/* If the screen size is 673px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .onHover {
    cursor: pointer;
  }

  .welcome-text {
    margin-left: -20;
  }

  .welcome-img {
    margin-left: 3%;
  }

  .h3-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 1rem;
  }

  .title-from {
    color: #45a648;
    margin-top: 10px;
    font-size: 20px;
    text-transform: uppercase;
  }

  .title-recent {
    color: #535353;
    font-size: 10px;
    margin-top: 10px;
  }

  .text-green {
    color: #45a648;
  }

  .text-grey {
    color: #535353
  }

  .atas {
    margin: 0;
  }

  .bawah {
    width: 100%;
    height: 40px;
    margin-left: 0;
    margin-right: 0;
    background: #5db960;
    border-radius: 0px 0px 12px 12px;
  }

  .recent-green-left {
    color: #ffffff;
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 118.88%;
    /* or 7px */

    text-transform: uppercase;
    color: #ffffff;
  }

  .recent-green-right {
    color: #ffffff;
    margin-right: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 118.88%;
    /* or 7px */

    text-transform: uppercase;
    color: #ffffff;
  }
}

/* If the screen size is 672 or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  .onHover {
    cursor: pointer;
  }

  .welcome-text {
    margin-left: 0%;
  }

  .welcome-img {
    margin-left: 3%;
  }

  .h3-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 1rem;
  }

  .title-recent {
    color: #535353;
    font-size: 10px;
  }

  .text-green {
    color: #45a648;
  }

  .atas {
    margin: 0;
  }

  .bawah {
    width: 100%;
    height: 40px;
    margin-left: 0;
    margin-right: 0;
    background: #5db960;
    border-radius: 0px 0px 12px 12px;
  }

  .recent-green-left {
    color: #ffffff;
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 118.88%;
    /* or 7px */

    text-transform: uppercase;

    color: #ffffff;
  }

  .recent-green-right {
    color: #ffffff;
    margin-right: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 118.88%;
    /* or 7px */

    text-transform: uppercase;
    color: #ffffff;
  }
}

.bth {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: rgba(72, 158, 75, 1);
}

.place-desc {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  white-space: nowrap;

  margin-top: -3px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  padding-bottom: 0;
}

.loc-wrap {
  white-space: pre-wrap;
  text-align: center;
}
</style>
